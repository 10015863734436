import React from 'react'
import { Link, graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import Img from 'gatsby-image'

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hat from '../components/hat';
import Products from '../components/products';
import Recipes from '../components/recipes';
import Stores from '../components/stores';

const propTypes = {
  data: PropTypes.object.isRequired,
}

class AboutPage extends React.Component {
  render() {
    const {
      title,
      body,
      image,
    } = this.props.data.content;
    return (
      <Layout>
        <SEO title={title} keywords={[]} />
        <div className='contentPage'>
          <div className="contentPageContent">
            <h1>{title}</h1>
            <div
              style={{whiteSpace: 'pre-line'}}
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            />
          </div>
          <div className="contentPageImage">
            {!!image && <Img {...image} />}
          </div>
        </div>
        <Hat />
        <Products id='producten' />
        <Hat />
        <Recipes id='recepten' />
        <Hat />
        <Stores />
      </Layout>
    )
  }
}

// AboutPage.propTypes = propTypes

export default AboutPage

export const pageQuery = graphql`
  query {
    content: contentfulContent(slug: { eq: "ministry-of-spicy-business" }) {
      id
      slug
      title
      image {
        id
        title
        fluid(maxWidth: 640, toFormat: JPG, quality: 75) {
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
